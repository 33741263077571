@import './_theme.less';
@import './_buttons.less';

.form-control-lg() {
  font-size: @fontSizeLg;
  padding: 14.75px 3*@padding;
  border-radius: 48.5px;
} 

form {
  margin-bottom: 0px;
}

.form-group {
  margin-bottom: @padding*2;

  &:last-of-type {
    margin-bottom: 0px;
  }

  &.switch {
    display: flex;
    align-items: center;

    & > label {
      padding-bottom: 0px;
      flex-grow: 1;
    }
  }
}

.form-control {
  width: @basePx * 30;
  padding: 1.5*@padding 2*@padding;
  border-radius: @roundBorderRadius;
  box-shadow: @boxShadow;
  font-family: @primaryFont;
  border: 0px;
  font-size: @fontSize;

  @media(max-width: 765px) {
    width: 100%;
  }

  &.block {
    width: 100%;
  }

  &.long {
    width: @basePx * 50;
    max-width: 100%;
  }

  &:focus {
    outline: 0;
    box-shadow: 0px 0px 0px 1px @secondaryColor;
    border-color: @secondaryColor;
  }

  &.lg {
    .form-control-lg();
  }
}

form.lg {
  .form-control {
    .form-control-lg();
  }

  .form-group {
    margin-bottom: @padding*2;
  }
}

select.form-control {
  padding-left: @padding*2 - 4px;

  &.no-value {
    color: @fontColorLight;
  }
}

.input-group {
  width: @basePx * 30;
  padding: 1.5*@padding 2*@padding;
  border-radius: @roundBorderRadius;
  box-shadow: @boxShadow;
  font-family: @primaryFont;
  border: 0px;
  font-size: @fontSize;
  display: flex;
  box-sizing: border-box;

  & > input,
  & > select,
  & > div {
    border: 0px;
    margin-right: @padding;
    flex-grow: 1;

    &:focus {
      outline: 0;
    }
  }

  &.long {
    width: @basePx * 50;
  }

  .btn {
    margin-top: -1.5*@padding;
    margin-bottom: -1.5*@padding;
    margin-right: -2*@padding;
    border: 0px;
    white-space: nowrap;
  }

  .btn.btn-remove {
    .button(#fff, @fontColor, @borderGrey, @errorColor);
    border: 0px;
  }

  .btn.btn-primary {
    .button(#fff, @primaryColor, @borderGrey, @primaryColor);
    border: 0px;
  }

  .btn.btn-secondary {
    .button(#fff, @secondaryColor, @borderGrey, @secondaryColor);
    border: 0px;
  }

  .btn,
  .btn.btn-primary,
  .btn.btn-secondary,
  .btn.btn-remove{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    position: relative;
    line-height: @fontSize;
  }

  a.btn,
  a.btn.btn-primary,
  a.btn.btn-secondary,
  a.btn.btn-remove {
    display: flex;
    align-items: center;
  }
}

.width-form-control-long {
  width: @basePx * 50;
}

.col-form {
  .form-group {
    display: flex;

    & > label,
    & > div:not(.input-group) {
      flex: 1
    }

    & > label {
      flex: 0 0 20 * @basePx;
      padding-top: @basePx * 1.5;
      color: @fontColor;
      margin-right: @padding;
    }

    & > div > .big-list,
    & > .big-list {
      box-shadow: @boxShadow;
      border-radius: @roundBorderRadius;
      overflow: hidden;
    }

    .form-static-value {
      margin-top: 2 * @padding;
      margin-bottom: @padding;
      font-weight: bold;
      color: @fontColorLight;
      padding: @padding 2*@padding;
      border-radius: 2 * @padding;
      background-color: @lightBackground;
      width: @basePx * 30;
      box-sizing: border-box;
    }
  }

  &.long {
    .form-control,
    .input-group,
    .form-group > .big-list, .form-group > div > .big-list,
    .form-static-value {
      width: @basePx * 50;
      max-width: 100%;
    }

    .form-control > .big-list .big-list-item,
    .form-control > div > .big-list .big-list-item {
      padding-left: @basePx * 2;
    }
  }

  &.full-width {
    .form-control {
      width: 100%
    }

    .input-group {
      width: 100%;
    }
  }

  &.switch-form {
    .form-group {
      margin: -@padding;
      margin-bottom: @padding;
      padding: @padding;
      transition: background-color 0.3s;
      
      &:hover {
        background-color: @lightBackground;
      }

      & > div:last-of-type {
        text-align: right;
        flex: 0;
      }

      label {
        font-size: @fontSizeLg;
        flex: 1;
        padding-right: 3*@padding;
        padding-top: 0px;

        small {
          font-size: @fontSize;
          color: @fontColorLight;
          display: block;
        }
      }
    }
  }
}

.regular-form {
  .form-group label {
    display: block;
    padding-bottom: @basePx;
    padding-top: 0px;
    margin-left: @padding;
    color: @fontColor;
    flex: none;

    & > .icon {
      fill: @fontColorLight;
      margin-right: @padding;
      position: relative;
      top: 2px;
    }
  }

  .form-group.switch {
    display: flex;

    & > label {
      padding-bottom: 0px;
      flex-grow: 1;
    }
  }

  .form-group {
    display: block;
  }

  &.space--2 {
    .form-group {
      margin-bottom: @padding*2;
    }
  }

  &.space--3 {
    .form-group {
      margin-bottom: @padding*3;
    }
  }
}

.col-form, .regular-form {
  .error-msg {
    color: @errorColor;
  }

  .error {
    color: @errorColor;
  }
}