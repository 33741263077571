@import './_theme.less';

.grid {
  display: flex;
  margin-left: -@padding;
  margin-right: -@padding;

  & > div {
    flex: 1;
    padding-left: @padding;
    padding-right: @padding;

    &.flex-grow--0 {
      flex-grow: 0;
    }

    &.flex-grow--1 {
      flex-grow: 1;
    }
  }
}

.flex-grow--0 {
  flex-grow: 0;
}

.flex-grow--1 {
  flex-grow: 1;
}

.box-shadow {
  box-shadow: @boxShadow;
}

.display--inline-block {
  display: inline-block;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.margin-bottom--0 {
  margin-bottom: 0;
}

.margin-bottom {
  margin-bottom: @padding;
}

.margin-bottom--2 {
  margin-bottom: @padding*2;
}

.margin-bottom--3 {
  margin-bottom: @padding*3;
}

.margin-bottom--4 {
  margin-bottom: @padding*4;
}

.margin-top--0 {
  margin-top: 0px;
}

.margin-top {
  margin-top: @padding;
}

.margin-top--2 {
  margin-top: @padding*2;
}

.margin-top--3 {
  margin-top: @padding*3;
}

.margin-top--4 {
  margin-top: @padding*4;
}

.margin-right--0 {
  margin-right: 0px;
}

.margin-right {
  margin-right: @padding;
}

.margin-right--2 {
  margin-right: @padding*2;
}

.margin-right--3 {
  margin-right: @padding*3;
}

.margin-right--4 {
  margin-right: @padding*4;
}

.margin-left--0 {
  margin-left: 0px;
}

.margin-left {
  margin-left: @padding;
}

.margin-left--2 {
  margin-left: @padding*2;
}

.margin-left--3 {
  margin-left: @padding*3;
}

.margin-left--4 {
  margin-left: @padding*4;
}

.padding-top {
  padding-top: @padding;
}

.padding-top--2 {
  padding-top: @padding*2;
}

.padding-top--3 {
  padding-top: @padding*3;
}

.padding-top--4 {
  padding-bottom: @padding*4;
}

.page-padding {
  padding: @padding * 2;
  box-sizing: border-box;
}

.w--400 {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.vertical-align--middle {
  display: flex;
  align-items: center;
}

.cursor--pointer {
  cursor: pointer;
}

.hidden {
  display: none;
}

.top--1 {
  position: relative;
  top: 1px;
}

.top--2 {
  position: relative;
  top: 2px;
}