@import './_theme.less';
@import './_buttons.less';

.btn.btn-primary {
  .button(@primaryColor, #fff, @primaryColor, #fff);
}

.btn.btn-secondary {
  .button(@secondaryColor, #fff, @secondaryColor, #fff);
}

.btn.btn-white {
  .button(#fff, @fontColor, #fff, @fontColor);
}

.btn.btn-remove {
  .button(#fff, @fontColor, #fff, @errorColor);
  .darkSpinner();
}

.btn.btn-remove-grey {
  .button(@lightBackground, @fontColor, @lightBackground, @errorColor);
  .darkSpinner();
}

.btn.btn-grey {
  .button(#fff, @fontColor, #fff, @fontColorLight);
}

.btn.btn-grey-primary {
  .button(@lightBackground, @fontColor, @lightBackground, @primaryColor);
}

.btn.btn-transparent {
  .button(transparent, @fontColor, transparent, @errorColor);
}

.btn.btn-transparent-secondary {
  .button(transparent, @fontColorLight, transparent, @errorColor);
  transition: all 0.3s;

  &:not(.static):not(.active):hover {
    transition: color 0.3s;
    border-color: @secondaryColor;

    svg {
      fill: @fontColor;
    }
  }

  &.active,
  &.active:hover {
    border: 1px solid @secondaryColor;
    svg {
      fill: #fff;
    }
  }

  .darkSpinner();
}

.btn.btn-input-height {
  padding-top: 9px;
  padding-bottom: 9px;
}

.btn.btn-page-options,
.btn.btn-grey-grey {
  .button(#fbfbfb, @fontColorLight, @lightBackground, @fontColorLight);
  transition: all 0.3s;
  .darkSpinner();
  
  svg {
    transition: fill 0.3s;
  }

  &:not(.static):not(.active):hover {
    transition: color 0.3s;
    background-color: #fbfbfb;
    color: @fontColor;
    border-color: @secondaryColor;

    svg {
      fill: @fontColor;
    }
  }

  &.active,
  &.active:hover {
    background-color: @secondaryColor;
    border: 1px solid @secondaryColor;
    svg {
      fill: #fff;
    }
  }
}

.btn.btn-only-icon {
  padding-top: 5px;
  svg {
    margin-right: 0px;
  }
}