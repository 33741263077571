@import './_theme.less';

@wbPadding: @padding*3;
@wbSmPadding: @padding*2;

.white-box {
  background-color: #fff;
  padding: @wbPadding;
  border-radius: @borderRadius;
  border: @borderThick;

  &.border-thin {
    border: @borderThin;
  }

  &.padding-sm {
    padding: @wbSmPadding;
  }

  .white-box-header {
    margin-top: -@wbPadding;
    margin-left: -@wbPadding;
    margin-right: -@wbPadding;
    border-bottom: 1px solid @borderLight;
    margin-bottom: @wbPadding;

    h2 {
      margin-bottom: 0px;
      margin-top: 0px;
      padding: 2*@padding @wbPadding;
      font-size: @fontSizeLg;

      svg {
        color: @fontColorLight;
        margin-right: @padding;
      }
    }
  }
}