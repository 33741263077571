@import './_theme.less';

.text-muted {
  color: @fontColorLight;
}

.text-italic {
  font-style: italic;
}

.text--400 {
  font-weight: 400;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-mono {
  font-family: 'Red Hat Mono', monospace;
}

.text-lg {
  font-size: @fontSizeLg;
}

.text-sm {
  font-size: @fontSizeSm;
}

.text-color--danger {
  color: @errorColor;
}

.text-color--info {
  color: @infoColor;
}

.line-height--button-height {
  line-height: @buttonHeight;
}

h1, h2, h3 {
  & > .icon {
    margin-right: @padding;
  }
}