@import './_theme.less';

svg.icon {
  width: @fontSize;
  height: @fontSize;
  display: inline-block;
  mask-size: cover;

  -webkit-transition: -webkit-transform .2s ease-in-out;
  -ms-transition: -ms-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  
  &.error {
    fill: @errorColor;
  }

  &.primary {
    fill: @primaryColor;
  }

  &.muted {
    fill: @fontColorLight;
  }

  &.white {
    fill: #fff;
  }

  &.markdown {
    width: @fontSize + 3px;
    height: @fontSize + 3px;
  }
}


a, span {
  svg.icon {
    position: relative;
    top: 2px;
    color: @fontColor;
  }

  svg.edit {
    top: 0px;
  }
}

svg.rotate90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

svg.rotate-90 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

svg.rotate180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

svg.flip-x {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}