.button(@bgColor, @textColor, @borderColor, @iconColor) {
  border-radius: @roundBorderRadius;
  padding: @padding 2*@padding;
  border: 1px solid @borderColor;
  color: @textColor;
  background-color: @bgColor;
  outline-color: @secondaryColor;
  font-size: @fontSize;
  display: inline-block;
  text-decoration: none;
  transition: background-color 0.3s;
  font-family: @primaryFont;
  box-sizing: border-box;
  text-align: center;

  &:not(.static):not([disabled]) {
    cursor: pointer;

    &:hover {
      background-color: darken(@bgColor, 8);

      .loader:after {
        background-color: darken(@bgColor, 8);
      }
    }
  }

  &[disabled] {
    background-color: lighten(@bgColor, 8%);
    border-color: lighten(@borderColor, 8%);
    cursor: not-allowed;
  }

  svg {
    position: relative;
    top: 2px;
    margin-right: @padding;
    fill: @iconColor;
  }

  &.btn-icon {
    padding: 0px;
    border: 0px;
    background-color: transparent;
  }

  .loader:after {
    transition: background-color 0.3s;
    background-color: @bgColor;
  }

  &.btn-block {
    display: block;
    width: 100%;
  }

  &.btn-lg {
    padding: 2*@padding 3*@padding;
    font-size: @fontSizeLg;
    border-radius: 50px;

    svg {
      width: 2*@basePx;
      height: 2*@basePx;
      top: 1px;
    }
  }
}

.darkSpinner() {
  .loader {
    &:before {
      background-color: #000;  
    }

    background: #000;
    background: -moz-linear-gradient(left, #000 10%, rgba(0, 0, 0, 0) 42%);
    background: -webkit-linear-gradient(left, #000 10%, rgba(0, 0, 0, 0) 42%);
    background: -o-linear-gradient(left, #000 10%, rgba(0, 0, 0, 0) 42%);
    background: -ms-linear-gradient(left, #000 10%, rgba(0, 0, 0, 0) 42%);
    background: linear-gradient(to right, #000 10%, rgba(0, 0, 0, 0) 42%);
  }
}