@keyframes fadeinBottom {
  from {
    opacity: 0;
    transform: translateY(30%);
  }
  to  { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeinBottom {
  from {
    opacity: 0;
    transform: translateY(30%);
  }
  to  { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinBottom {
  from {
    opacity: 0;
    transform: translateY(30%);
  }
  to  { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeinBottom {
  from {
    opacity: 0;
    transform: translateY(30%);
  }
  to  { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadeinBottom {
  from {
    opacity: 0;
    transform: translateY(30%);
  }
  to  { opacity: 1; }
}

.fadein-bottom {
  animation-name: fadeinBottom;
  animation-duration: .4s;
}

.fadein-bottom-fast {
  animation-name: fadeinBottom;
  animation-duration: .2s;
}

@keyframes fadeinTop {
  from {
    opacity: 0;
    transform: translateY(-30%);
  }
  to  { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeinTop {
  from {
    opacity: 0;
    transform: translateY(-30%);
  }
  to  { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinTop {
  from {
    opacity: 0;
    transform: translateY(-30%);
  }
  to  { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeinTop {
  from {
    opacity: 0;
    transform: translateY(-30%);
  }
  to  { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadeinTop {
  from {
    opacity: 0;
    transform: translateY(-30%);
  }
  to  { opacity: 1; }
}

.fadein-top {
  animation-name: fadeinTop;
  animation-duration: .4s;
}

.fadein-top-fast {
  animation-name: fadeinTop;
  animation-duration: .2s;
}

@keyframes fadeinLeft {
  from {
    opacity: 0;
    transform: translateX(-30%);
  }
  to  { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeinLeft {
  from {
    opacity: 0;
    transform: translateX(-30%);
  }
  to  { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeinLeft {
  from {
    opacity: 0;
    transform: translateX(-30%);
  }
  to  { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeinLeft {
  from {
    opacity: 0;
    transform: translateX(-30%);
  }
  to  { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadeinLeft {
  from {
    opacity: 0;
    transform: translateX(-30%);
  }
  to  { opacity: 1; }
}

.fadein-left {
  animation-name: fadeinLeft;
  animation-duration: .4s;
}

.fadein-left-fast {
  animation-name: fadeinLeft;
  animation-duration: .2s;
}