@import './_theme.less';

.note {
  padding: @padding*2;
  border-left: 2px solid @borderGreyDark;
  background-color: @lightBackground;

  &.note-info {
    background-color: lighten(@infoColor, 60%);
    border-color: @infoColor;

    .icon {
      fill: @infoColor;
    }
  }

  &.note-success {
    background-color: lighten(@successColor, 70%);
    border-color: @successColor;
  }

  &.note-error {
    background-color: lighten(@errorColor, 30%);
    border-color: @errorColor
  }
}