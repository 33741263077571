@import '../../style/_theme.less';
@import '../../style/buttons.less';
@import '../../style/form.less';
@import '../../style/icons.less';
@import '../../style/transitions.less';
@import '../../style/layout.less';
@import '../../style/typography.less';
@import '../../style/note.less';
@import '../../style/white-box.less';

body {
  font-family: @primaryFont;
  font-size: @fontSize;
  padding: 0px;
  margin: 0px;
}

.hide {
  display: none;
}

.flex-wrap {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
  
  .left-col {
    width: 600px;
    background-color: @lightBackground;
    height: 100vh;
  }
  
  .right-col {
    background: @primaryColor;
    background: linear-gradient(241deg, rgba(6,121,133,1) 58%, rgba(244,244,244,1) 42%);
    height: 100vh;
    flex-grow: 1;
  }

  .white-box {
    width: 400px;
    text-align: left;
    margin: auto;
  }
}

.align-middle-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.login-box {
  width: 350px;

  .login-options {
    .login-option {
      position: relative;
      display: block;
      .button(#fff, @primaryColor, @primaryColor, @primaryColor);

      & {
        margin-bottom: @padding*2;
      }

      svg.icon {
        height: 20px;
        width: 20px;
        position: absolute;
        left: @padding*3;
        top: 14px;
      }
    }
  }
}

#login-box {
  #option-password {
    display: none;
  }

  &.option-password {
    .login-options {
      display: none;
    }

    #option-password {
      display: block;
    }
  }
}

.text-button {
  border: 0px;
  padding: 0px;
  color: @fontColorLight;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: @fontColor;
  }
}